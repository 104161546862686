export const DATATABLE_LANGUAGE = {
  search: "",
  searchPlaceholder: "Suchen",
  paginate: {
    first: "Erste",
    last: "Letzte",
    next: "Nächste",
    previous: "Zurück",
  },
  loadingRecords: "Lädt...",
  zeroRecords: "Keine Ergebnisse gefunden",
  emptyTable: "Keine Ergebnisse gefunden",
  info: "",
  infoEmpty: "Zeige 0 von 0 Einträgen",
  infoFiltered: "",
  lengthMenu: "",
  processing: "<i class='fa fa-circle-notch fa-spin fa-2x fa-fw'></i>",
};
